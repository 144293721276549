<template>
	<div class="bodys">
		<!-- <el-tabs v-model="typefirst" @tab-click="tabpaneclick" editable @edit="handleTabsEdit2"> -->
		<el-tabs v-model="typefirst" @tab-click="tabpaneclick">
			<el-tab-pane :name="exim.value" v-for="(exim,types) in typefirstlist" :key="types">
				<span slot="label">
					<!-- <i class="el-icon-edit" @click.stop="addtabs(2,exim)"></i> -->
					{{exim.label}}
				</span>
				<el-button class="buttones" type="primary" icon="el-icon-plus"
					@click="$router.push({ path: 'addBudgetProgramme',query:{type:typefirst}})">新增方案
				</el-button>
				<el-table border ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%">
					<!-- <el-table-column type="selection" width="55">
					</el-table-column> -->
					<el-table-column type="index" label="序号" width="50">
					</el-table-column>
					<el-table-column label="名称" prop="useproShixunContent">
					</el-table-column>
					<el-table-column label="简介" prop="useproDescribe">
					</el-table-column>
					<el-table-column label="创建时间">
						<template slot-scope="scope" v-if="scope.row.useproCreateTime">
							{{moment(scope.row.useproCreateTime).format('YYYY-MM-DD HH:mm:SS')}}
						</template>
					</el-table-column>
					<el-table-column label="单价" prop="useproPrice" show-overflow-tooltip>
						<template slot-scope="scope" v-if="scope.row.useproPrice">
							{{scope.row.useproPrice}}万元
						</template>
					</el-table-column>
					<el-table-column label="操作" width="130">
						<template slot-scope="scope">
							<el-button size="mini"
								@click="$router.push({ path: 'addDemandScheme',query:{id:scope.row.id}})" type="primary"
								icon="el-icon-edit">
							</el-button>
							<el-button size="mini" type="primary" icon="el-icon-delete"
								@click="deleteProgramme(scope.row.id)"></el-button>
						</template>
					</el-table-column>
				</el-table>
			</el-tab-pane>
		</el-tabs>
		<el-dialog :visible.sync="dialogVisible">
			<el-input v-model="classificationvalue" placeholder="请输入内容"></el-input>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="add()">确 定</el-button>
				<el-button @click="dialogVisible = false">取 消</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import Moment from 'moment'
	export default {
		components: {},
		data() {
			return {
				userId: sessionStorage.getItem('userId'),
				dialogVisible: false,
				moment: Moment,
				typefirst: "1",
				editshow: false,
				dialogindex: 0,
				selectionshow: false,
				tableData: [],
				typefirstlist: [{
					label: '0-100万元',
					value: "1"
				}, {
					label: '100-500万元',
					value: "2"
				}, {
					label: '500万元以上',
					value: "3"
				}],
				classificationvalue: '',
			}
		},
		created() {
			this.getqueryDemand(1)

		},
		mounted() {

		},
		methods: {
			async getqueryDemand(lable) {
				this.tableData = []
				await this.$post('queryDemand', {
					userId: this.userId,
					userSign: lable
				}).then((res) => {
					console.log(res)
					this.tableData = res
				})
				this.$set(this.tableData)
			},
			deleteProgramme(id) {
				this.$confirm('此操作将删除该方案, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					beforeClose(action, instance, done) {
						if (action == "confirm") {
							instance.$refs.confirm.$el.onclick = function(e) {
								e = e || window.event
								console.log(e.detail)
								if (e.detail != 0) {
									done()
								}
							}
						} else {
							done()
						}
					}
				}).then(() => {

				})
			},
			//修改
			addtabs(type, data) {
				this.dialogVisible = true

			},
			//一级二级分类添加修改
			add(Id) {
				if (this.classificationvalue) {

				} else {
					this.$message({
						type: 'warning',
						message: '请输入内容'
					});
				}
			},
			//点击tab栏二级分类删除添加时
			handleTabsEdit2(targetName, action) {

			},
			//装修风格点击tab栏
			tabpaneclick(val) {
				console.log(val.name)
				this.getqueryDemand(val.name)
			},

		}
	}
</script>

<style scoped lang="less">
	.bodys {
		.buttones {
			margin: 0 0 40px 0;
		}
	}
</style>
<style>
	.el-tabs__new-tab {
		width: 30px;
		height: 30px;
		line-height: 30px;
		font-size: 20px;
	}

	.el-tabs--left .el-tabs__header.is-left .el-tabs__new-tab {
		float: left;
	}
</style>
